'use strict';

// Define jQuery
var $ = jQuery;

/**
 * Set start-hero height after screen height
 */

var setDimensions = function()
{
	var screenWidth = $(window).width();

	if(screenWidth > 991) {
		var windowsHeight  = $(window).height();
		var header 			 = $('.header');
		var benefitsHeight = $('.intro-benefits');

		// If benfits show on start screen
		// var total = windowsHeight - benefitsHeight.height() - header.height();
	} else {
		var windowsHeight  = $(window).height();
		var header 			 = $('.header');
		var heroContainer  = $('.start-hero');
		var benefitsHeight = $('.intro-benefits');
		var total = windowsHeight;
	}

	/**
	 * When resizing the site
	 * we adjust the heights of the sections
	 */
	 /*
	$(window).resize(function() {
	  setDimensions();
	});
	*/
};

/*
 * Check what device user use
 * If small device, we make the movie off
 * and show background image instead.
 */

var heroVideo = function()
{
	$( window ).on( 'resize', function() {
		if ( $( window ).width() < 991 )
		{
			if ( $( '#hero-player' ).css( 'display' ) !== 'none' )
			{
				$( '#hero-player' ).css( 'display', 'none' );
			}
		}
		else
		{
			if ( $( '#hero-player' ).css( 'display' ) !== 'block' )
			{
				$( '#hero-player' ).css( 'display', 'block' );
			}
		}
	})
};

var arrowScroll = function()
{
	var arrow = $('.hero-arrow');

	arrow.on('click', function( e )
	{
		var header = $('.header');
		var ScrollGoal = $('.intro-benefits');

		var offset = ScrollGoal.offset();
		var top = offset.top;
		var total = ( top - header.height() );

		e.preventDefault();
		$.scrollTo(total, 200);
	});
};

/**
 * When user click on return button, we scroll down to '#benefits' section.
 * This is for single-product and benefit
 */

var returnButton = function() {
	var btn  	= $('.button-holder__return');
	var header	= $('.header');
	var goal 	= $('#benefits');

	var res = (goal - header.height());

	btn.on('click', function() {
		$('html, body').animate({
			scrollTop: goal.offset().top - header.height()
		}, 200);
	});
};
