'use strict';

var $ = jQuery;

/**
 * Check highest hight of all videos and set that hight.
 * Every videos get the same height then.
 */

var videoHeight = function() {
	var video = $('.movie');
	var screenWidth	= $(window).width();

	if(screenWidth > 991) {
		video.each(function() {
			$(this).height('auto');

			var videoHeight = video.map(function() {
				return $(this).height();
			}).get();

			/**
			 * Math.max takes a variable number of arguments
			 * 'apply' is equivalent to passing each height as an argument
			 */

			var maxHeight = Math.max.apply(null, videoHeight);

			// Set each height to the max height
			video.height(maxHeight);
		 });
	}
};

/**
 * When user click at one video we get the 'video-id'
 * we call onYouTubeIframeAPIReady function to pass the id
 * Then we auto-scroll the user to the player and append the title
 * to the video-placeholder.
 */

var showVideo = function() {
	var video 		 = $('.movie');
	var playingNow  = $('.watching-now');

	video.on('click', function() {
		var header		 = $('.header');
		var placeholder = $('.player-placeholder');
			 placeholder.css('display', 'block');

		var videoId 	= $(this).data('id');
		var videoName 	= $(this).data('name');
		var goal 		= placeholder.offset();
		var goalTop 	= goal.top;
		var total 		= (goalTop - header.height());

		playingNow.html('<h3 class="watching-now__title">Du kollar på: ' + videoName + '</h3>');
		player.loadVideoById({
			'videoId': videoId,
			'suggestedQuality': 'hd720'
		});

		$.scrollTo(total, 200);
	});
};

var playVideo = function() {
	if($('#player-trigger-play-video').length === 1) {
		var id = $('#player-trigger-play-video').data('id');
		$('.movie[data-id="'+ id +'"]').trigger('click');
	}
};
