'use strict';

var $ = jQuery;

$( '.wrap' ).css( 'margin-top', $( '.header' ).height() + 15 );

/**
 * Our main that wraps everything gets a padding-top of our header
 * Cuz our header has a fixed position.
 */

var contentPush = function()
{
	var header = $('.header');
	var main = $('.main');

	main.css('padding-top', header.height());
};

var popUp = function()
{
	var hero = $('.start-hero');
	var header = $('.header');
	var popBox = $('.pop-up');
	var profileImg = $('.pop-up__profile-image');
	var close = $('.pop-up__close');
	var screenWidth = $(window).width();

	if (localStorage.getItem('popState') !== 'shown')
	{
		if (screenWidth > 991)
		{
			$(window).scroll(function()
			{
				if ($(this).scrollTop() >= (hero.outerHeight() - header.height()))
				{
					popBox.animate(
					{
						bottom: '-0px'
					}, 500);

					profileImg.animate(
					{
						bottom: '-0px'
					}, 500);
				}
			});
		}

		localStorage.setItem('popState', 'shown');
	}

	close.on('click', function(e)
	{
		e.preventDefault();
		popBox.css('display', 'none');
	});
};

/**
 * Loads the IFrame Player API code asynchronously.
 */

var ytApi = function()
{
	var tag = document.createElement('script');
	tag.src = "https://www.youtube.com/iframe_api";

	var firstScriptTag = document.getElementsByTagName('script')[0];
	firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
};

var player;
var heroPlayer;
var YT;

var playVideo = function()
{
	if ($('#player-trigger-play-video').length === 1)
	{
		var id = $('#player-trigger-play-video').data('id');
		$('.movie[data-id="' + id + '"]').trigger('click');
	}
};


var onYouTubeIframeAPIReady = function()
{
	var ytID = $('#hero-player').data('play-id');
	/**
	 * Hero
	 */

	heroPlayer = new YT.Player('hero-player',
	{
		width: '640',
		height: '360', // 115%
		videoId: ytID,
		events:
		{
			'onReady': function( event ) {
				event.target.setPlaybackQuality('hd1080');
			},
			'onPlaybackQualityChange': function(event) {
    			console.log("YouTube quality changed to: " + event.target.getPlaybackQuality());
			},
			'onStateChange': function(event)
			{
				if (event.data == YT.PlayerState.PLAYING)
				{
					event.target.setPlaybackQuality('hd1080');
				}

				if (event.data === YT.PlayerState.ENDED)
				{
					heroPlayer.playVideo();
				}
			}
		},
		playerVars:
		{
			'autoplay': 1,
			'controls': 1,
			'autohide': 0,
			'showinfo': 0,
			'loop': 1,
			'rel': 0
		},
	});

	/**
	 * Kundfilmer
	 */

	player = new YT.Player('player',
	{
		height: '390',
		width: '100%',
		videoId: '',
		events:
		{
			'onReady': playVideo
		},
		playerVars:
		{
			'rel': 0
		}
	});
};